(function() {
        window.onload = update_terminal_resolution;
        window.onresize = update_terminal_resolution;

        function update_terminal_resolution() {
                let Terminal_resolution = document.getElementById("terminal-resolution");
                let viewport_width = document.documentElement.scrollWidth;
                let viewport_height = document.documentElement.scrollHeight;
                Terminal_resolution.textContent = `${viewport_width}x${viewport_height}`;
        }
})();
