class cTerminal extends HTMLDivElement {
        constructor() {
                super();
        }

        connectedCallback() {
                this.setAttribute("id", "terminal");
                this.innerHTML = `
                <span class="f2">Terminal - <span id="terminal-resolution">Calculating...</span></span>
                `;
        }
}

class cNav extends HTMLDivElement {
        constructor() {
                super();
        }

        connectedCallback() {
                this.innerHTML = `
                <nav id="nav">
                        <a id="about-anchor" href="${__webpack_public_path__ + "index.html"}">[ About ]</a>
                        <a id="blog-anchor" href="${__webpack_public_path__ + "blog.html"}">[ Blog ]</a>
                        <a href="https://youtube.com/@CTRLRTY?si=gW_UXxjGRqjLdB4P">[ YouTube ]</a>
                </nav>
                `;

        }
}

class cPrompt extends HTMLDivElement {
        constructor() {
                super();
        }

        connectedCallback() {
                this.setAttribute("id", "prompt");
                this.innerHTML = `
                <div class="prompt-ps f1">
                        ┌─[Guest][Puppet]<br />
                        ├─[~/pages]<br />
                        └─# <span class="prompt-input">cat ${window.location.pathname}</span>
                </div>
                `;
        }
}

class cCopyright extends HTMLDivElement {
        constructor() {
                super();
        }

        connectedCallback() {
                this.innerHTML = `
                <p class="copyright">Copyright 2023 Mind Control Machine</p>
                `;
        }
}

class cOpening extends HTMLDivElement {
        constructor() {
                super();
        }

        connectedCallback() {
                const maxSpaceLeft = 18;
                const maxSpaceRight = 17;
                const txtAttr = this.getAttribute("text");

                const spaceLeft = maxSpaceLeft - Math.ceil(txtAttr.length / 2)
                const spaceRight = maxSpaceRight - Math.ceil(txtAttr.length / 2) + (txtAttr.length % 2)

                let txt = "|"
                for(let i = 0; i < spaceLeft-1; ++i)
                        txt += " "

                txt += txtAttr

                for(let i = 0; i < spaceRight-1; ++i)
                        txt += " "
                txt += "|"

                this.innerHTML = `
                <pre id="opening">
  _  _       ___     _  _
 / |/ |    _/__ \`,_ | \\/ |
/_/ |_|ind 'Control |_||_|achine
                                
+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+
${txt}
+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+
                </pre>
                `;
        }
}

customElements.define("c-terminal", cTerminal, {extends: "div"});
customElements.define("c-nav", cNav, {extends: "div"});
customElements.define("c-prompt", cPrompt, {extends: "div"});
customElements.define("c-copyright", cCopyright, {extends: "div"});
customElements.define("c-opening", cOpening, {extends: "div"});
