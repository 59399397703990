export function disable_scroll() {
        let body = window.body;
        body.style.top = `-${window.scrollY}px`;
        body.style.position = 'fixed';
        body.style.width = '100vw';
}

export function enable_scroll() {
        let body = window.body; 
        let scrollY = parseInt(body.style.top) * -1;
        body.style.position = '';
        body.style.top = '';
        body.style.width = '';
        scrollTo(0, scrollY);
}

export function insert_modal() {
        const Fragment = new DocumentFragment();
        const ShowcaseModal = document.createElement("div");
        const Img = document.createElement("img");

        Fragment.append(ShowcaseModal);
        ShowcaseModal.append(Content);
        Content.append(Img);

        ShowcaseModal.setAttribute("class", "modal modal-off");
        Content.setAttribute("class", "sm-img");
        Img.setAttribute("id", "sm-img");
}
