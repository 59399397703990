import '../common.js';

import {disable_scroll, enable_scroll} from '../tool.js';

import './index.css';

import './securityPlus_thumbnail.png';
import './sorbet.png';
import './prime_generator.png';
import './portfolio_thumbnail.png';
import './sysfadder.png';
import './chess_pizza.png';
import './usbdr.png';
import './simple_site.png';
import './wuenak_tenan.png';

(function(){
        const Modal = document.getElementById("showcase-modal");

        Modal.addEventListener("click", (e) => {
                if (e.target != Modal)
                        return;

                if (Modal.classList.contains("modal-on")) {
                        Modal.classList.replace("modal-on", "modal-off")
                        enable_scroll();
                }
        });

        let SC_items = document.getElementsByClassName("sc-item");
        let Modal_image = document.getElementById("sm-img");
        let Modal_p = document.getElementById("sm-p");

        for(let i = 0; i < SC_items.length; ++i) {
                SC_items[i].addEventListener("click", (e) => {
                        if (Modal.classList.contains("modal-off")) {
                                Modal.classList.replace("modal-off", "modal-on")
                                let SC_img = SC_items[i].getElementsByClassName("sc-image")[0];
                                let SC_detail = SC_items[i].getElementsByClassName("sc-detail")[0];
                                Modal_image.src = SC_img.src;
                                Modal_p.innerHTML = SC_detail.innerHTML;
                                disable_scroll();
                        }
                });
        }
})();
